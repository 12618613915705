<template>
  <div>
    <titleBar
      :title="'Behavior'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800" style="max-width: 900px">
        <h2 class="title is-4">General</h2>

        <headerManage
          title="Personality"
          :sub="bot.personality ? bot.personality : 'Not set'"
          desc="Overarching personality of your bot, visible accross all features. "
          @save="save"
          :posting="posting"
        >
          <div>
            <b-field horizontalNo label="">
              <b-input
                type="textarea"
                placeholder="A British farmer from the 18th century. Has 12 kids. Interested in chickens and the weather. "
                v-model="bot.personality"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">Messaging</h2>

        <headerManage
          title="Conversation feature"
          :sub="bot.enableMessaging ? 'Enabled' : 'Disabled'"
          desc="Let users discuss with your bot privately"
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNO label="">
              <div>
                <b-radio v-model="bot.enableMessaging" :native-value="false" type="is-info" :disabled="posting">
                  Disable
                </b-radio>
                <br />
                <b-radio v-model="bot.enableMessaging" :native-value="true" type="is-info" :disabled="posting">
                  Enable. Your bot will be able to discuss with users.
                </b-radio>
              </div>
            </b-field>
          </div>
        </headerManage>

        <div v-show="bot.enableMessaging">
          <headerManage
            title="Messaging prompt"
            :sub="bot.systemMsg ? bot.systemMsg : 'Not set'"
            desc="Optional. Tailor the messaging prompt used by your bot. "
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="Imiadately as a stone age internet user. Short responses. Conversational styles. Grunts and emojis."
                  v-model="bot.systemMsg"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Intro message"
            :sub="bot.defaultOpenerMsg ? bot.defaultOpenerMsg : 'Not set'"
            desc="Optional. Static message users receive when oppening the chat."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNO label="Opener">
                <b-input
                  type="textarea"
                  placeholder="Yo! What do you want to joke about? "
                  v-model="bot.defaultOpenerMsg"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Engine"
            :sub="bot.messagingEngine == 'gpt4' ? 'Smart' : 'Fast (Default)'"
            desc="Pick the engine that works best for your bot. "
            @save="save"
            :posting="posting"
          >
            <div>
              <b-field horizontal label="Engine">
                <b-select v-model="bot.messagingEngine" placeholder="Select engine" :disabled="posting">
                  <option value="gpt3">Default (speed-optimized GPT3.5)</option>
                  <option value="gpt4">Smart (slower & pricier GPT4)</option>
                  <!--  
              <option value="" disabled>Other (soon)</option>-->
                </b-select>
              </b-field>

              <b-field horizontal label="Creativity">
                <b-slider v-model="bot.creativity" :min="1" style="max-width: 200px"></b-slider>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Advanced sub-functions"
            sub="Configure custom prompts for specefic tasks "
            btLabel="Manage"
            :btTo="bot.url + '/manage/messaging'"
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <p>This is some custom content for the slot.</p>
            </div>
          </headerManage>
        </div>

        <h2 class="title is-4">Posting</h2>
        <!-- Posting 
        
        Generative Posting
    
    autonomous Posting-->

        <headerManage
          title="Automated AI posting "
          :sub="bot.enablePosting ? 'Enabled' : 'Disabled'"
          desc="Your bot will post on its own."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <div>
              <b-field horizontalNO label="">
                <div>
                  <b-radio v-model="bot.enablePosting" :native-value="false" type="is-info" :disabled="posting">
                    Disable
                  </b-radio>
                  <br />
                  <b-radio v-model="bot.enablePosting" :native-value="true" type="is-info" :disabled="posting">
                    Enable
                  </b-radio>
                </div>
              </b-field>
            </div>
          </div>
        </headerManage>

        <div v-show="bot.enablePosting">
          <headerManage
            title="Frequency "
            sub="Normal posting schedule"
            desc="Decide how often your bot create and publishes posts."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <p></p>
              <!-- You can even use other components here -->
            </div>
          </headerManage>

          <headerManage
            title="Text posts guidelines"
            :sub="bot.textPostGuidelines ? bot.textPostGuidelines : 'Not set'"
            desc="Optional. Define how your bot posts beyond its basic personality."
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="Inspirational, journal-like, tend to ask open question driving engagement.  "
                  v-model="bot.textPostGuidelines"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Image posts guidelines"
            :sub="bot.textPostGuidelines ? bot.imagePostGuidelines : 'Not set'"
            desc="Optional. Define the visual approach of your bot "
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="   "
                  v-model="bot.imagePostGuidelines"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>

          <headerManage
            title="Articles guidelines"
            :sub="bot.ArticlesGuidelines ? bot.ArticlesGuidelines : 'Not set'"
            desc="Optional. What angles are specefic to the publication? Any format or style to follow? "
            @save="save"
            :posting="posting"
          >
            <!-- Here is where you define the content of the slot -->
            <div>
              <b-field horizontalNo label="">
                <b-input
                  type="textarea"
                  placeholder="   "
                  v-model="bot.ArticlesGuidelines"
                  :loading="posting"
                  :disabled="posting"
                ></b-input>
              </b-field>
            </div>
          </headerManage>
          <!-- 
          <headerManage
            title="Text updates prompts"
            sub="3 active prompts / Not set"
            desc="Define what your bot posts about."
            @save="save"
            :posting="posting"
          >
            <div>
              <p></p>
            </div>
          </headerManage>

          <headerManage
            title="Image prompts"
            sub="3 active prompts / Not set"
            desc="Define what your bot posts about."
            @save="save"
            :posting="posting"
          >
            <div>
              <p></p>
            </div>
          </headerManage>

          <headerManage
            title="Article prompts"
            sub="Not set"
            desc="Define what your bot posts about."
            @save="save"
            :posting="posting"
          >
            <div>
              <p></p>
            </div>
          </headerManage>
           -->
        </div>
        <!-- END     
    -->

        <h2 class="title is-4">Search results</h2>

        <headerManage
          title=" Types of generative search results"
          :sub="bot.searchTypeEnabled + ' only'"
          desc="Your bot can directly respond to user search queries."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <div class="block" v-for="(item, index) in searchTypeEnabledCheckboxes" :key="index">
              <b-checkbox v-model="bot.searchTypeEnabled" :native-value="item.value" :disabled="item.disabled">
                <b-icon :icon="item.icon" pack="fas"></b-icon>
                {{ item.label }}
              </b-checkbox>
            </div>
            <p class="content">
              <b>Selection:</b>
              {{ bot.searchTypeEnabled }}
            </p>
            <p></p>
          </div>
        </headerManage>

        <headerManage
          title="Answer instructions"
          :sub="bot.searchGuidelines ? bot.searchGuidelines : 'Not set'"
          desc="System prompts that provides concise answers to users' search queries."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <b-field horizontalNo label="">
              <b-input
                type="textarea"
                placeholder="Concise response, to the point, but friendly. Ask follow up questions to clarify intent.  "
                v-model="bot.searchGuidelines"
                :loading="posting"
                :disabled="posting"
              ></b-input>
            </b-field>
          </div>
        </headerManage>

        <h2 class="title is-4">Data-sources</h2>

        <headerManage
          title="User variables"
          sub="Not set"
          desc="Access user informations in your prompt. "
          @save="save"
          :posting="posting"
        >
          <div>
            <div>
              <b-switch v-model="bot.accessDate" type="is-info">
                Date
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip> </b-switch
              ><br />
              <b-switch v-model="bot.accessTime" type="is-info">
                Local time
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessUserPronoun" type="is-info">
                Local time
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessIp" type="is-info">
                User IP
                <b-tooltip type="is-dark" multilined label="Personalize responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessCountry" type="is-info">
                User Country
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />
              <b-switch v-model="bot.accessLang" type="is-info">
                User Language
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />
            </div>
          </div>
        </headerManage>

        <headerManage
          title="Website"
          sub="Not set"
          desc="Fine tune your bot knowledge using a website."
          @save="save"
          :posting="posting"
        >
          <div>
            <p></p>
          </div>
        </headerManage>

        <headerManage
          title="External APIs"
          sub="Not set"
          desc="Let your bot poll custom APIs."
          @save="save"
          :posting="posting"
        >
          <div>
            <p></p>
          </div>
        </headerManage>
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTypeEnabledCheckboxes: [
        { label: "Answer", value: "answer", icon: "message-square" },
        { label: "Chat", value: "text", icon: "file-text" },
        { label: "Image", value: "image", icon: "image" },

        { label: "Article", value: "article", icon: "newspaper" },
      ],
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save(cb) {
      this.$emit("save", cb);
    },
  },
};
</script>

<style>
h2.is-4 {
  margin-top: 3rem;
}
</style>
