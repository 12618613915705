<template>
  <div>
    <div class="line" v-show="!isOpen" :class="{ updated: justUpdated }">
      <div div class="h">
        <h2 class="title is-6">{{ title }}</h2>

        <h4 class="subtitle is-6">{{ sub }}</h4>
      </div>

      <div class="buttons">
        <router-link :to="btTo" v-if="btTo" class="">
          <b-button size="is-small" type="is-text" icon-right="chevron-right">
            {{ btLabel }}
          </b-button>
        </router-link>

        <b-button v-else size="is-small" type="is-text" @click="isOpen = !isOpen">
          {{ btLabel }}
        </b-button>
      </div>
    </div>

    <b-collapse aria-id="contentIdForA11y2" class=" " animation="none" v-model="isOpen">
      <div class="box">
        <button type="button" aria-label="Close notification" class="delete" @click="isOpen = !isOpen"></button>
        <h2 class="title is-6">{{ title }}</h2>
        <p class="subtitle is-7">{{ desc }}</p>
        <slot></slot>
        <hr />
        <b-field horizontalNO label="   ">
          <span>
            <b-button type="is-dark is-small is-outlinedNO right" @click="save" :loading="posting" :disabled="posting"
              >Save changes</b-button
            >

            <b-button type="is-dark is-small is-outlined" @click="isOpen = !isOpen" :disabled="posting"
              >Cancel</b-button
            >
          </span>
        </b-field>
      </div>
    </b-collapse>
    <hr />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      justUpdated: false,
    };
  },
  props: {
    btLabel: {
      type: String,
      default: "Edit",
    },
    btTo: {
      type: String,
      // default: "Edit",
    },
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      required: false,
    },
    desc: {
      type: String,
      required: false,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit("button-click");
    },
    save() {
      this.$emit("save", this.saveCompletedCallback);
    },
    saveCompletedCallback(success) {
      // alert(3);
      this.isOpen = false; //just close the fucker
      this.justUpdated = true;
      //wait 1 sec than set to false using settimout

      setTimeout(() => (this.justUpdated = false), 500);
    },
  },
};
</script>

<style scoped>
/* Additionaly adjustments for specific vertical alignment */

.buttons {
  display: flex;
  align-items: center;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: all 2s;
}

.line.updated {
  background: rgb(247, 251, 202);
  transition: all 2s;
}

.h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.delete {
  float: right;
}
.box {
  margin-top: 20px;
  border: 1px #d4d4d4 solid;
  border-radius: 15px;
}

.right {
  float: right;
}
</style>
